import React, { useState, useEffect } from "react";
import {
  Space,
  Table,
  Button,
  Modal,
  Spin,
  message,
  Tabs,
  Upload,
  Form,
  Input,
  Typography,
  Collapse,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import ApiService from "@/services/api.service";
import { DomainPlesk, WordpressSite } from "@/types";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { useLocation } from "react-router-dom";
import { DigitalOceans } from "../DigitalOceans/DigitalOceans";
import * as XLSX from "xlsx";

const { Panel } = Collapse;
const { confirm } = Modal;
const { TabPane } = Tabs;
const ThemesAndPlugins = () => {
  const { state } = useLocation();
  const [loadingListPlugin, setLoadingListPlugin] = useState(true);
  const [loadingListTheme, setLoadingListTheme] = useState(true);
  const prefix_download_plugin_link =
    "https://domainlabs-api.directdevs.com/api/Containers/plugins/download/";
  const prefix_download_theme_link =
    "https://domainlabs-api.directdevs.com/api/Containers/themes/download/";
  const [selectedPlugins, setSelectedPlugins] = useState<Plugin[]>([]);
  const [selectedThemes, setSelectedThemes] = useState<Theme[]>([]);
  const [selectedWordpressSites, setSelectedWordpressSites] = useState<
    WordpressSite[]
  >([]);
  const [listPlugins, setListPlugins] = useState<Plugin[]>();
  const [listThemes, setListThemes] = useState<Theme[]>();
  const [listWordpressSites, setListWordpressSites] = useState<WordpressSite[]>(
    []
  );

  const [namePlugin, setNamePlugin] = useState<string>();
  const [nameTheme, setNameTheme] = useState<string>();
  const [versionPlugin, setVersionPlugin] = useState<string>();
  const [versionTheme, setVersionTheme] = useState<string>();
  const [nameFileUpload, setNameFileUpload] = useState<string>();
  const [nameFileUploadTheme, setNameFileUploadTheme] = useState<string>();
  const [openAddPluginModal, setOpenAddPluginModal] = useState<boolean>(false);
  const [openAddThemeModal, setOpenAddThemeModal] = useState<boolean>(false);
  const [openListWordpressModal, setOpenListWordpressModal] =
    useState<boolean>(false);
  const [openListWordpressModal2, setOpenListWordpressModal2] =
    useState<boolean>(false);
  const [openListVPSModal, setOpenListVPSModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [infoEncoded, setInfoEncoded] = useState<any>();
  const [isUploadPlesk, setIsUploadPlesk] = useState<boolean>(false);

  const propsUploadPlugin: UploadProps = {
    name: "file",
    action: ApiService.endPoint + "/Containers/plugins/upload",
    beforeUpload: (file) => {
      const isZip = file.type === "application/x-zip-compressed";
      if (!isZip) {
        message.error(`"${file.name}" is not a zip file`);
      }
      return isZip || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setNameFileUpload(info.file.name);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const propsUploadTheme: UploadProps = {
    name: "file",
    action: ApiService.endPoint + "/Containers/themes/upload",
    beforeUpload: (file) => {
      const isZip = file.type === "application/x-zip-compressed";
      if (!isZip) {
        message.error(`"${file.name}" is not a zip file`);
      }
      return isZip || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setNameFileUploadTheme(info.file.name);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleAddPlugin = () => {
    setOpenAddPluginModal(true);
  };
  const handleAddTheme = () => {
    setOpenAddThemeModal(true);
  };
  const handleOK_AddPlugin = async () => {
    if (!namePlugin) {
      message.warning("Please input Plugin Name.");
      return;
    }
    if (!versionPlugin) {
      message.warning("Please input Plugin Version.");
      return;
    }
    if (!nameFileUpload) {
      message.warning("Please upload File.");
      return;
    }
    const data = {
      name: namePlugin,
      version: versionPlugin,
      slug: nameFileUpload.substring(0, nameFileUpload.indexOf(".")),
      link: nameFileUpload,
    };
    try {
      const result = await ApiService.post("/Plugins", data);
      if (result) {
        fetchListPlugin();
      }
    } catch (err) {
      message.error(err);
    }

    setNamePlugin(undefined);
    setVersionPlugin(undefined);
    setNameFileUpload(undefined);
    setOpenAddPluginModal(false);
  };
  const handleOK_AddTheme = async () => {
    if (!nameTheme) {
      message.warning("Please input Theme Name.");
      return;
    }
    if (!versionTheme) {
      message.warning("Please input Theme Version.");
      return;
    }
    if (!nameFileUploadTheme) {
      message.warning("Please upload File.");
      return;
    }
    const data = {
      name: nameTheme,
      version: versionTheme,
      slug: nameFileUploadTheme.substring(0, nameFileUploadTheme.indexOf(".")),
      link: nameFileUploadTheme,
    };
    try {
      const result = await ApiService.post("/Themes", data);
      if (result) {
        fetchListTheme();
      }
    } catch (err) {
      message.error(err);
    }

    setNameTheme(undefined);
    setVersionTheme(undefined);
    setNameFileUploadTheme(undefined);
    setOpenAddThemeModal(false);
  };
  const handleCancel_AddPlugin = () => {
    setOpenAddPluginModal(false);
  };
  const handleCancel_AddTheme = () => {
    setOpenAddThemeModal(false);
  };
  const handleCancel = () => {
    setOpenListWordpressModal(false);
  };
  const handleCancel_Uninstall = () => {
    setOpenListWordpressModal2(false);
  };
  const wpInstances = async (state: any) => {
    setListWordpressSites([]);
    setInfoEncoded(state);
    try {
      await ApiService.getWithParams("/Domains/getListWordpressInstances", {
        infoEncoded: state,
      }).then(({ data }) => setListWordpressSites(data.result));
    } catch (err) {
      setOpenListWordpressModal(false);
      message.error("Connection failed! Please try again.", 10);
    }
  };
  const fetchListWordpress = async (state: any) => {
    setLoadingData(true);
    await Promise.all([wpInstances(state)]);
    setLoadingData(false);
  };

  const { confirm } = Modal;

  const showResultModal = (
    title: string,
    content: JSX.Element,
    exportData: string[],
    filename: string
  ) => {
    Modal.info({
      title,
      content: (
        <div>
          {content}
          <Button
            type="primary"
            onClick={() => exportToExcel(exportData, filename)}
            style={{ marginTop: "10px" }}
          >
            Export to Excel
          </Button>
        </div>
      ),
      onOk() {},
    });
  };

  const exportToExcel = (data: string[], filename: string) => {
    const worksheet = XLSX.utils.aoa_to_sheet(
      data.map((item, index) => [index + 1, item])
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Results");
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  const handleInstallThemesAndPlugins = async (
    themes: Theme[],
    plugins: Plugin[],
    websites: WordpressSite[]
  ) => {
    let result: any[] = [];
    if (!websites.length) {
      message.error("Please choose websites.");
      return;
    }
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        let installResults: string[] = [];
        try {
          // Uninstall themes and plugins first
          await handleUninstallThemesAndPluginsWithoutConfirm(themes, plugins, websites);

          // Install themes
          if (themes.length) {
            const listThemeLink = themes.map(
              (theme) => prefix_download_theme_link + theme.link
            );
            for (const website of websites) {
              const body = {
                id: website.id,
                list: listThemeLink,
                infoEncoded,
              };
              try {
                const res = await ApiService.post(
                  "/Domains/installListThemeWP",
                  body
                );
                console.log("install themes", res);
                result.push(res);
                installResults.push(
                  `Themes installed for website ${website.siteUrl}: ${JSON.stringify(res.data)}`
                );
                await ApiService.post("/Domains/clearCache", {
                  instanceId: website.id,
                  infoEncoded,
                });
              } catch (err) {
                console.error(
                  `Error installing themes for website ${website.siteUrl}:`,
                  err
                );
                installResults.push(
                  `Error installing themes for website ${website.siteUrl}: ${err.message}`
                );
              }
            }
            message.success("Install themes successfully!", 5);
          }

          // Install plugins
          if (plugins.length) {
            const listPluginLink = plugins.map(
              (plugin) => prefix_download_plugin_link + plugin.link
            );
            for (const website of websites) {
              const body = {
                id: website.id,
                list: listPluginLink,
                infoEncoded,
              };
              try {
                const res = await ApiService.post(
                  "/Domains/installAndActivateListPluginWP",
                  body
                );
                console.log("install plugins", res);
                result.push(res);
                let data = res.data.result.map( e => {
                  return {code: e.code, stdout: e.stdout}
                })
                installResults.push(
                  // `Plugins installed for website ${website.siteUrl}: ${JSON.stringify(res.data)}`
                  `Plugins installed for website ${website.siteUrl}: ${JSON.stringify(data)}`
                );
                await ApiService.post("/Domains/clearCache", {
                  instanceId: website.id,
                  infoEncoded,
                });
              } catch (err) {
                console.error(
                  `Error installing plugins for website ${website.siteUrl}:`,
                  err
                );
                installResults.push(
                  `Error installing plugins for website ${website.siteUrl}: ${err.message}`
                );
              }
            }
            message.success("Install plugins successfully!", 5);
          }
          // setOpenListWordpressModal(false);
          showResultModal(
            "Installation Result",
            <div>
            </div>,
            installResults,
            "Installation_Results"
          );
        } catch (err) {
          console.error("General error:", err);
          message.error("Error: " + err.message);
          // setOpenListWordpressModal(false);
          showResultModal(
            "Installation Error",
            <div>
              <p>An error occurred during installation.</p>
              <p>{err.message}</p>
            </div>,
            [],
            "Installation_Error"
          );
        }
      },
      onCancel() {},
    });
  };

  const handleUninstallThemesAndPlugins = async (
    themes: Theme[],
    plugins: Plugin[],
    websites: WordpressSite[]
  ) => {
    let result: any[] = [];
    if (!websites.length) {
      message.error("Please choose websites.");
      return;
    }
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        let uninstallResults: string[] = [];
        try {
          if (themes.length) {
            const listThemeName = themes.map((theme) => theme.slug);
            const themePromises = websites.map(async (website) => {
              try {
                const body = {
                  id: website.id,
                  list: listThemeName,
                  infoEncoded,
                };
                const res = await ApiService.post(
                  "/Domains/deleteListThemeWP",
                  body
                );
                console.log("Uninstall themes", res);
                result.push(res);
                uninstallResults.push(
                  `Themes uninstalled for website ${website.siteUrl}: ${JSON.stringify(res.data)}`
                );
                await ApiService.post("/Domains/clearCache", {
                  instanceId: website.id,
                  infoEncoded,
                });
              } catch (err) {
                console.error(
                  `Error uninstalling themes for website ${website.siteUrl}:`,
                  err
                );
                uninstallResults.push(
                  `Error uninstalling themes for website ${website.siteUrl}: ${err.message}`
                );
              }
            });
            await Promise.all(themePromises);
            message.success("Uninstall themes successfully!", 5);
          }
          if (plugins.length) {
            const listPluginName = plugins.map((plugin) => plugin.slug);
            const pluginPromises = websites.map(async (website) => {
              try {
                const body = {
                  id: website.id,
                  list: listPluginName,
                  infoEncoded,
                };
                const res = await ApiService.post(
                  "/Domains/deleteListPluginWP",
                  body
                );
                console.log("Uninstall plugins", res);
                result.push(res);
                uninstallResults.push(
                  `Plugins uninstalled for website ${website.siteUrl}: ${JSON.stringify(res.data)}`
                );
                await ApiService.post("/Domains/clearCache", {
                  instanceId: website.id,
                  infoEncoded,
                });
              } catch (err) {
                console.error(
                  `Error uninstalling plugins for website ${website.siteUrl}:`,
                  err
                );
                uninstallResults.push(
                  `Error uninstalling plugins for website ${website.siteUrl}: ${err.message}`
                );
              }
            });
            await Promise.all(pluginPromises);
            message.success("Uninstall plugins successfully!", 5);
          }
          // setOpenListWordpressModal(false);
          showResultModal(
            "Uninstallation Result",
            <div>
            </div>,
            uninstallResults,
            "Uninstallation_Results"
          );
        } catch (err) {
          console.error("General error:", err);
          message.error("Error: " + err.message);
          // setOpenListWordpressModal(false);
          showResultModal(
            "Uninstallation Error",
            <div>
              <p>An error occurred during uninstallation.</p>
              <p>{err.message}</p>
            </div>,
            [],
            "Uninstallation_Error"
          );
        }
      },
      onCancel() {},
    });
  };
  const handleUninstallThemesAndPluginsWithoutConfirm = async (
    themes: Theme[],
    plugins: Plugin[],
    websites: WordpressSite[]
  ) => {
    let result: any[] = [];
    if (!websites.length) {
      message.error("Please choose websites.");
      return;
    }
        let uninstallResults: string[] = [];
        try {
          if (themes.length) {
            const listThemeName = themes.map((theme) => theme.slug);
            const themePromises = websites.map(async (website) => {
              try {
                const body = {
                  id: website.id,
                  list: listThemeName,
                  infoEncoded,
                };
                const res = await ApiService.post(
                  "/Domains/deleteListThemeWP",
                  body
                );
                console.log("Uninstall themes", res);
                result.push(res);
                uninstallResults.push(
                  `Themes uninstalled for website ${website.siteUrl}: ${JSON.stringify(res.data)}`
                );
                await ApiService.post("/Domains/clearCache", {
                  instanceId: website.id,
                  infoEncoded,
                });
              } catch (err) {
                console.error(
                  `Error uninstalling themes for website ${website.siteUrl}:`,
                  err
                );
                uninstallResults.push(
                  `Error uninstalling themes for website ${website.siteUrl}: ${err.message}`
                );
              }
            });
            await Promise.all(themePromises);
            // message.success("Uninstall themes successfully!", 5);
          }
          if (plugins.length) {
            const listPluginName = plugins.map((plugin) => plugin.slug);
            const pluginPromises = websites.map(async (website) => {
              try {
                const body = {
                  id: website.id,
                  list: listPluginName,
                  infoEncoded,
                };
                const res = await ApiService.post(
                  "/Domains/deleteListPluginWP",
                  body
                );
                console.log("Uninstall plugins", res);
                result.push(res);
                uninstallResults.push(
                  `Plugins uninstalled for website ${website.siteUrl}: ${JSON.stringify(res.data)}`
                );
                await ApiService.post("/Domains/clearCache", {
                  instanceId: website.id,
                  infoEncoded,
                });
              } catch (err) {
                console.error(
                  `Error uninstalling plugins for website ${website.siteUrl}:`,
                  err
                );
                uninstallResults.push(
                  `Error uninstalling plugins for website ${website.siteUrl}: ${err.message}`
                );
              }
            });
            await Promise.all(pluginPromises);
            // message.success("Uninstall plugins successfully!", 5);
          }
          // setOpenListWordpressModal(false);
         
        } catch (err) {
          console.error("General error:", err);
          // message.error("Error: " + err.message);
          // setOpenListWordpressModal(false);
         
        }
 
  };

  const handleDeletePlugin = async (record: Plugin) => {
    confirm({
      title: "Are you sure to delete this plugin?",
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          const res = await ApiService.delete("/Plugins/" + record.id);
          if (res) {
            fetchListPlugin();
          }
        } catch (err) {
          console.log(err);
          message.error("Error: " + err);
        }
      },
      onCancel() {},
    });
  };
  const handleDeleteTheme = async (record: Theme) => {
    confirm({
      title: "Are you sure to delete this theme?",
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          const res = await ApiService.delete("/Themes/" + record.id);
          if (res) {
            fetchListTheme();
          }
        } catch (err) {
          console.log(err);
          message.error("Error: " + err);
        }
      },
      onCancel() {},
    });
  };
  const handleUploadPlesk = async () => {
    setOpenListVPSModal(true);
    setIsUploadPlesk(true);
  };
  const runUploadPlesk = async (state: any) => {
    confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "You cannot undo this action.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          if (selectedThemes.length) {
            const listThemeLink = selectedThemes.map(
              (theme) => prefix_download_theme_link + theme.link
            );
            let result = await ApiService.post(
              "/Domains/uploadListThemeToPlesk",
              { list: listThemeLink, infoEncoded: state }
            );
            console.log("upload themes to plesk", result);
            message.success("Upload themes successfully!", 5);
          }
          if (selectedPlugins.length) {
            const listPluginLink = selectedPlugins.map(
              (plugin) => prefix_download_plugin_link + plugin.link
            );
            let result = await ApiService.post(
              "/Domains/uploadListPluginToPlesk",
              { list: listPluginLink, infoEncoded: state }
            );
            console.log("upload plugins to plesk", result);
            message.success("Upload plugins successfully!", 5);
          }
        } catch (err) {
          console.log(err);
          message.error("Error: " + err);
        }
      },
      onCancel() {},
    });
  };

  interface Plugin {
    id: number;
    name: string;
    version: string;
    slug: string;
    link: string;
  }
  interface Theme {
    id: number;
    name: string;
    version: string;
    slug: string;
    link: string;
  }
  const columnsPlugins: ColumnsType<Plugin> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (text) => <a href={prefix_download_plugin_link + text}>{text}</a>,
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <>
          <Space>
            {/* <Button onClick={() => handleEditPlugin(record)}>Edit</Button> */}
            <Button danger onClick={() => handleDeletePlugin(record)}>
              Delete
            </Button>
          </Space>
        </>
      ),
    },
  ];
  const columnsThemes: ColumnsType<Theme> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (text) => <a href={prefix_download_theme_link + text}>{text}</a>,
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <>
          <Space>
            <Button danger onClick={() => handleDeleteTheme(record)}>
              Delete
            </Button>
          </Space>
        </>
      ),
    },
  ];
  const columnsWordpressInstance: ColumnsType<WordpressSite> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "URL",
      dataIndex: "siteUrl",
      key: "siteUrl",
    },
  ];

  const fetchListPlugin = async () => {
    setLoadingListPlugin(true);
    await ApiService.get("/Plugins").then((res) => {
      setListPlugins(res.data);
    });
    setLoadingListPlugin(false);
  };
  const fetchListTheme = async () => {
    setLoadingListTheme(true);
    await ApiService.get("/Themes").then((res) => {
      setListThemes(res.data);
    });
    setLoadingListTheme(false);
  };

  useEffect(() => {
    Promise.all([fetchListPlugin(), fetchListTheme()]);
  }, []);

  const rowSelectionPlugins = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Plugin[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedPlugins(selectedRows);
    },
  };
  const rowSelectionThemes = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Theme[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedThemes(selectedRows);
    },
  };
  const rowSelectionWordpressSites = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: WordpressSite[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedWordpressSites(selectedRows);
    },
  };

  // Modal Select VPS
  const onChange_CollapseStatus = (key: string | string[]) => {
    if (Array.isArray(key)) {
      if (key.length > 1) {
      }
    }
  };

  const handleCancel_ChooseVPS = () => {
    setOpenListVPSModal(false);
  };

  return (
    <Space>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Themes" key="1">
          <Space direction="vertical">
            <Space>
              <Button type="primary" onClick={handleAddTheme}>
                Add Theme
              </Button>
              <Button
                type="primary"
                onClick={handleUploadPlesk}
                disabled={selectedThemes.length ? false : true}
              >
                Upload to Plesk
              </Button>
              <Button
                type="primary"
                onClick={() => setOpenListVPSModal(true)}
                disabled={selectedThemes.length ? false : true}
              >
                Install / Uninstall
              </Button>
              {/* <Button type="primary" onClick={()=> setOpenListVPSModal(true)} disabled={selectedThemes.length?false:true}>Uninstall</Button> */}
            </Space>
            <Spin tip="Loading..." size="large" spinning={loadingListTheme}>
              <Table
                rowKey="id"
                rowSelection={{
                  type: "checkbox",
                  ...rowSelectionThemes,
                }}
                columns={columnsThemes}
                dataSource={listThemes}
                rowClassName="editable-row"
              />
            </Spin>
            {/* <Modal
              destroyOnClose
              open={openListWordpressModal2}
              title="Select websites"
              onCancel={handleCancel_Uninstall}
              footer={[
                <Button key="back" onClick={handleCancel_Uninstall}>
                  Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={() => handleUninstallThemesAndPlugins(selectedThemes, selectedPlugins, selectedWordpressSites)}>
                  Uninstall
                </Button>,
              ]}
              width={700}
              style={{ top: 20 }}
            >
              <Table
                rowKey="id"
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelectionWordpressSites,
                }}
                columns={columnsWordpressInstance}
                dataSource={listWordpressSites}
                rowClassName="editable-row"
              />
            </Modal> */}
            <Modal
              destroyOnClose
              open={openListVPSModal}
              title="Select VPS"
              onCancel={handleCancel_ChooseVPS}
              footer={[]}
              width={1900}
              style={{ top: 20 }}
            >
              <DigitalOceans
                runUploadPlesk={runUploadPlesk}
                isUploadPlesk={isUploadPlesk}
                readOnly={true}
                setOpenListWordpressModal={setOpenListWordpressModal}
                fetchListWordpressSites={fetchListWordpress}
              />
            </Modal>
            <Modal
              destroyOnClose
              open={openListWordpressModal}
              title="Select websites"
              onCancel={handleCancel}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button
                  key="install"
                  type="primary"
                  loading={loading}
                  onClick={() =>
                    handleInstallThemesAndPlugins(
                      selectedThemes,
                      selectedPlugins,
                      selectedWordpressSites
                    )
                  }
                >
                  Install
                </Button>,
                <Button
                  key="uninstall"
                  danger
                  type="primary"
                  loading={loading}
                  onClick={() =>
                    handleUninstallThemesAndPlugins(
                      selectedThemes,
                      selectedPlugins,
                      selectedWordpressSites
                    )
                  }
                >
                  Uninstall
                </Button>,
              ]}
              width={700}
              style={{ top: 20 }}
            >
              <Spin tip="Loading..." size="large" spinning={loadingData}>
                <Table
                  rowKey="id"
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelectionWordpressSites,
                  }}
                  columns={columnsWordpressInstance}
                  dataSource={listWordpressSites}
                  rowClassName="editable-row"
                />
              </Spin>
            </Modal>
            <Modal
              destroyOnClose
              open={openAddThemeModal}
              title="Add Theme"
              onOk={handleOK_AddTheme}
              onCancel={handleCancel_AddTheme}
              width={500}
              style={{ top: 20 }}
            >
              <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true }]}
                >
                  <Input
                    value={nameTheme}
                    onChange={(e) => setNameTheme(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name="version"
                  label="Version"
                  rules={[{ required: true }]}
                >
                  <Input
                    value={versionTheme}
                    onChange={(e) => setVersionTheme(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name="file"
                  label="Upload"
                  rules={[{ required: true }]}
                >
                  <Upload {...propsUploadTheme}>
                    <Button icon={<UploadOutlined />}>Upload zip only</Button>
                  </Upload>
                </Form.Item>
              </Form>
            </Modal>
          </Space>
        </TabPane>
        <TabPane tab="Plugins" key="2">
          <Space direction="vertical">
            <Space>
              <Button type="primary" onClick={handleAddPlugin}>
                Add Plugin
              </Button>
              <Button
                type="primary"
                onClick={handleUploadPlesk}
                disabled={selectedPlugins.length ? false : true}
              >
                Upload to Plesk
              </Button>
              <Button
                type="primary"
                onClick={() => setOpenListVPSModal(true)}
                disabled={selectedPlugins.length ? false : true}
              >
                Install / Uninstall
              </Button>
              {/* <Button type="primary" onClick={()=> setOpenListVPSModal(true)} disabled={selectedPlugins.length?false:true}>Uninstall</Button> */}
            </Space>
            <Spin tip="Loading..." size="large" spinning={loadingListPlugin}>
              <Table
                rowKey="id"
                rowSelection={{
                  type: "checkbox",
                  ...rowSelectionPlugins,
                }}
                columns={columnsPlugins}
                dataSource={listPlugins}
                rowClassName="editable-row"
              />
            </Spin>
            <Modal
              destroyOnClose
              open={openAddPluginModal}
              title="Add Plugin"
              onOk={handleOK_AddPlugin}
              onCancel={handleCancel_AddPlugin}
              width={500}
              style={{ top: 20 }}
            >
              <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true }]}
                >
                  <Input
                    value={namePlugin}
                    onChange={(e) => setNamePlugin(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name="version"
                  label="Version"
                  rules={[{ required: true }]}
                >
                  <Input
                    value={versionPlugin}
                    onChange={(e) => setVersionPlugin(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name="file"
                  label="Upload"
                  rules={[{ required: true }]}
                >
                  <Upload {...propsUploadPlugin}>
                    <Button icon={<UploadOutlined />}>Upload zip only</Button>
                  </Upload>
                </Form.Item>
              </Form>
            </Modal>
          </Space>
        </TabPane>
      </Tabs>
    </Space>
  );
};
export default ThemesAndPlugins;
